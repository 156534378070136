@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
// root
:root {
  font-size: 16px;
}

// grid
.container {
  @include media-breakpoint-down(xs) {
    max-width: 100%;
  }
}

// form
label {
  color: theme-color('unavailable');
}

// buttons
.btn {
  font-weight: 600;
  border-radius: $border-radius;
  /*border-top-left-radius: unset;
  border-top-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: unset;*/
  transition: box-shadow .1s ease-in-out;

  &:not(.shadow-none):hover {
    box-shadow: 0px 3px 6px #00000029 !important;
  }

  .icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
  }

  &.btn-primary {
    /*color: theme-color('secondary') !important;*/

    &.btn-sm {
      background-color: $btn-sm-primary !important;
      border-color: $btn-sm-primary !important;
    }

    &-light {
      background-color: $btn-primary-light !important;
      border-color: $btn-primary-light !important;
      color: $btn-primary-light-text !important;

      &:hover {
        background-color: darken($btn-primary-light, 10) !important;
        border-color: darken($btn-primary-light, 10) !important;
      }
    }

    &:hover {
      background-color: darken(theme-color('primary'), 10) !important;
      border-color: darken(theme-color('primary'), 10) !important;
    }
  }

  &.btn-secondary {
    &:hover {
      background-color: darken(theme-color('secondary'), 10) !important;
      border-color: darken(theme-color('secondary'), 10) !important;
    }
  }

  &.btn-danger {
    &:hover {
      background-color: darken(theme-color('danger'), 15) !important;
      border-color: darken(theme-color('danger'), 15) !important;
    }
  }

  &.btn-outline-light {
    &:hover {
      color: theme-color('secondary') !important;
      background-color: theme-color('primary') !important;
      border-color: theme-color('primary') !important;
    }
  }

  &.btn-outline-secondary {
    &:hover {
      color: $white !important;
      background-color: theme-color('secondary') !important;
      border-color: theme-color('secondary') !important;
    }
  }

  &.btn-sm {
    border-top-right-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;;  
  }
}

// caret
.dropdown-toggle::after {
  left: 0.1rem;
}

// Ng Select
ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: $danger !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: $input-height !important;
  border: $input-border-width solid $input-border-color !important;
}

// Nav 
.nav-pills {
  .nav-item {
    background-color: gray('50');
    .nav-link {
        box-shadow: none;
        &.active {
            transform: scale(1.05);
            box-shadow: 0 4px 18px -4px rgba(theme-color('secondary'), 0.65);
        }
    }
  }
}

// Accordion
.accordion {
  .card {
    .card-header {
      .btn {
        &.btn-link {
          &:hover {
            box-shadow: none !important;
          }    
        }
      }
    }
  } 
}