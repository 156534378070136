@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.btn-like {
  padding: $btn-padding-y $btn-padding-x;
  background-color: $gray-50;
  border-top-left-radius: unset;
  border-top-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: unset;
  text-align: center;

  &.btn-like-reverse {
    border-top-left-radius: $border-radius;
    border-top-right-radius: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: $border-radius;
  }
}

.ceo-title {
  text-indent: -9999px;
  position: absolute;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.link-underline {
  text-decoration: underline !important;
}

.align-sub {
  vertical-align: sub;
}

.btn-ico  {
  margin: 0 5px;
  background-color: #EBEDEE;
  padding: 7px 10px;
  border-radius: unset;
  border-radius: 12px;
  i {
    color: #707A7F;
  }
  &:hover {
    background-color: #d0d5d8;
  }
}

form .error-form {
  position: static;
  padding-bottom: 7px;
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.list-dashed {
  list-style-type: none;
  padding-left: 1.3rem;

  li {
      &::before {
          content: '-';
          position: absolute;
          margin-left: -20px;
      }

      &:not(:last-child) {
          margin-bottom: 1rem;
      }
  }
}

.block-ui-spinner > .message {
  white-space: pre-line;
}

.text-line-through {
  text-decoration: line-through;
}

.text-primary-light {
  color: $primary-light !important;
}

.text-primary-light-alt {
  color: $primary-light-alt !important;
}

.bg-secondary-dark {
	background-color: $secondary-dark !important;
}