//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

$enable-responsive-font-sizes: true;

// theme-colors
$primary: #37BFAF !default;
$primary-light: #85D4C8 !default;
$primary-light-alt: #90d9cf !default;
$secondary: #072530 !default;
$secondary-dark: #021821 !default;
$green: #00B9B0 !default;
$red: #F5333F !default;
$cyan: #1AA7E5 !default;
$orange: #EB7100 !default;
$purple: #5100EB !default;
$dark-gray: #393939 !default;

// color states
$info: $cyan !default;
$warning: $orange !default;
$verify: $purple !default;
$unavailable: $dark-gray !default; 

//colors
$white: #fff !default;

// grays
$gray-10: #fafafa !default;
$gray-50: #ebedee !default;
$gray-60: #79868D !default;

// Add colors to corresponding map
$theme-colors: (
  "green": $green,
  "verify": $verify,
  "unavailable": $unavailable,
);

$grays: (
  "10": $gray-10,
  "50": $gray-50,
  "60": $gray-60,
);

// body
$body-color: #393939 !default;
$body-bg: $gray-10 !default;

// font
$headings-font-family: 'Garet' !default;
$headings-font-weight: 700 !default;
$headings-color: #004055 !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 900 !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.6 !default;
$h3-font-size: $font-size-base * 2.2 !default;
$h4-font-size: $font-size-base * 1.8 !default;
$h5-font-size: $font-size-base * 1.4 !default;
$h6-font-size: $font-size-base !default;

// links
$link-color: $green !default;

// common components
$border-radius:               0.8rem !default;
$border-radius-lg:            2.3rem !default;
$border-radius-sm:            .8rem !default;

// buttons
$btn-font-family: 'Garet' !default;
$btn-padding-y: .725rem !default;
$btn-border-width: 2px !default;
$btn-line-height: 1.5 !default;
$btn-sm-primary: #00b5af !default;
$btn-primary-light: $primary-light-alt !default;
$btn-primary-light-text: #144049 !default;

$btn-padding-y-sm: .725rem !default;

// forms
$input-border-radius: calc($border-radius * .15) !default;

// navbar
$navbar-nav-link-padding-x:         .75rem !default;

$navbar-dark-color:                 rgba($white, 1) !default;
$navbar-dark-active-color:          $primary !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, 0) !default;

// nav
$nav-pills-border-radius:           unset !default;
$nav-pills-link-active-color:       $white !default;
$nav-pills-link-active-bg:          $secondary !default;

// card
$card-border-radius: $border-radius !default;

// modals
$modal-xl: 1140px !default;
$modal-lg: 900px !default;
$modal-md: 650px !default;
$modal-sm: 500px !default;

$modal-content-border-radius: $border-radius;

// badge
$badge-border-radius: $border-radius !default;
$badge-font-size: 75% !default;
$badge-padding-y: 0.5rem !default;
$badge-padding-x: 1rem !default;
$badge-font-weight: 600 !default;