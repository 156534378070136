//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom variables
$content-padding: 0rem !default;

//  ------------------------------
//    footer
//  ------------------------------
$footer-height: 0rem !default;