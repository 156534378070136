@font-face {
    font-family: 'Garet';
    src: local('Garet Medium'), local('Garet-Medium'),
        url('../../fonts/Garet/Garet-Medium.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Regular'), local('Garet-Regular'),
        url('../../fonts/Garet/Garet-Regular.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Medium Italic'), local('Garet-MediumItalic'),
        url('../../fonts/Garet/Garet-MediumItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Light'), local('Garet-Light'),
        url('../../fonts/Garet/Garet-Light.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Light Italic'), local('Garet-LightItalic'),
        url('../../fonts/Garet/Garet-LightItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Regular Italic'), local('Garet-RegularItalic'),
        url('../../fonts/Garet/Garet-RegularItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Thin italic'), local('Garet-Thinitalic'),
        url('../../fonts/Garet/Garet-Thinitalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Thin'), local('Garet-Thin'),
        url('../../fonts/Garet/Garet-Thin.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Hairline';
    src: local('Garet Hairline'), local('Garet-Hairline'),
        url('../../fonts/Garet/Garet-Hairline.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Hairline.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Fat';
    src: local('Garet Fat Italic'), local('Garet-FatItalic'),
        url('../../fonts/Garet/Garet-FatItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-FatItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Hairline';
    src: local('Garet Hairline Italic'), local('Garet-HairlineItalic'),
        url('../../fonts/Garet/Garet-HairlineItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-HairlineItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Extra';
    src: local('Garet Extra Bold Italic'), local('Garet-ExtraBoldItalic'),
        url('../../fonts/Garet/Garet-ExtraBoldItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Fat';
    src: local('Garet Fat'), local('Garet-Fat'),
        url('../../fonts/Garet/Garet-Fat.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Fat.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Heavy Italic'), local('Garet-HeavyItalic'),
        url('../../fonts/Garet/Garet-HeavyItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Heavy'), local('Garet-Heavy'),
        url('../../fonts/Garet/Garet-Heavy.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Black Italic'), local('Garet-BlackItalic'),
        url('../../fonts/Garet/Garet-BlackItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Book';
    src: local('Garet Book'), local('Garet-Book'),
        url('../../fonts/Garet/Garet-Book.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Bold'), local('Garet-Bold'),
        url('../../fonts/Garet/Garet-Bold.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Extra';
    src: local('Garet Extra Bold'), local('Garet-ExtraBold'),
        url('../../fonts/Garet/Garet-ExtraBold.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Bold Italic'), local('Garet-BoldItalic'),
        url('../../fonts/Garet/Garet-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet Book';
    src: local('Garet Book Italic'), local('Garet-BookItalic'),
        url('../../fonts/Garet/Garet-BookItalic.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-BookItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Garet';
    src: local('Garet Black'), local('Garet-Black'),
        url('../../fonts/Garet/Garet-Black.woff2') format('woff2'),
        url('../../fonts/Garet/Garet-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

.font-garet {
    font-family: "Garet";
}
